import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container, Row, Col } from '../ui/Wrapper';
import Heading from '../ui/Heading';
import Swiper from '../ui/swiper';
import { SectionWrap } from './style';

const CenteredSlider = ({ sliderSettings, images, title }) => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <Heading as="h3" mb="60px" textalign="center">
              {title}
            </Heading>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Swiper settings={sliderSettings}>
              {images.map((image, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="item" key={index}>
                  <GatsbyImage image={getImage(image)} alt={`image-${index}`} />
                </div>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

CenteredSlider.propTypes = {
  sliderSettings: PropTypes.object,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};

CenteredSlider.defaultProps = {
  sliderSettings: {
    slidesPerView: 1,
    centeredSlides: false,
    loop: false,
    arrows: true,
    speed: 1000,
    paginationStyle: {
      mt: '50px',
    },
    breakpoints: {
      1499: {
        slidesPerView: 3,
      },

      991: {
        slidesPerView: 2,
      },

      767: {
        slidesPerView: 1,
      },
      320: {
        slidesPerView: 1,
      },
    },
  },
};

export default CenteredSlider;
