import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Hero from '../../../components/hero';
import { device } from '../../../theme';

const UnitHeroArea = ({ unit }) => {
  const heroData = useStaticQuery(graphql`
    query HeroQuery {
      allUnitJson(filter: { id: { regex: "/hero-content/" } }) {
        edges {
          node {
            id
            unit
            title
            subtitle
            action {
              label
              link
            }
            bgImage {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 768, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                  presentationWidth
                  presentationHeight
                }
              }
            }
            mobileBgImage {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    node: { title, subtitle, action, bgImage, mobileBgImage },
  } = heroData.allUnitJson.edges.find(({ node }) => node.unit === unit);

  const backgroundImage = [
    mobileBgImage.childImageSharp.fluid,
    {
      ...bgImage.childImageSharp.fluid,
      media: device.smallOnly,
    },
  ];

  return <Hero title={title} subtitle={subtitle} action={action} backgroundImage={backgroundImage} dark />;
};

UnitHeroArea.propTypes = {
  unit: PropTypes.oneOf(['portao', 'bacacheri']).isRequired,
};

export default UnitHeroArea;
