import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import CenteredSlider from '../../../components/centered-slider';

const PictureArea = ({ unit }) => {
  const data = useStaticQuery(graphql`
    query PictureQueres {
      allUnitJson(filter: { id: { regex: "/pictures-content/" } }) {
        edges {
          node {
            unit
            title
            pictures {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    }
  `);

  const {
    node: { title, pictures },
  } = data.allUnitJson.edges.find(({ node }) => node.unit === unit);

  return <CenteredSlider title={title} images={pictures} />;
};

PictureArea.propTypes = {
  unit: PropTypes.oneOf(['portao', 'bacacheri']).isRequired,
};

export default PictureArea;
