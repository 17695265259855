import React from 'react';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import PropTypes from 'prop-types';
import MapMarker from '../../assets/images/icons/map-marker.png';
import { MapsContainer } from './style';

const ACCESS_TOKEN = process.env.GATSBY_MAPBOX_TOKEN;

const Maps = ({ center }) => {
  const [viewport, setViewport] = React.useState({
    latitude: center.lat,
    longitude: center.lng,
    zoom: 16,
  });

  return (
    <MapsContainer marker="animated">
      <ReactMapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxApiAccessToken={ACCESS_TOKEN}
        onViewportChange={setViewport}
      >
        <Marker latitude={center.lat} longitude={center.lng} offsetLeft={-20} offsetTop={-10}>
          <img src={MapMarker} alt="Map Marker" />
        </Marker>
      </ReactMapGL>
    </MapsContainer>
  );
};

Maps.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
};

export default Maps;
