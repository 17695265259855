import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import CenteredSlider from '../../components/centered-slider';

const ApprovedStudents = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutUsJson {
        approved {
          title
          images {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  `);

  const { title, images } = data.aboutUsJson.approved;

  return <CenteredSlider title={title} images={images} />;
};

export default ApprovedStudents;
