import PropTypes from 'prop-types';
import React from 'react';
import ApprovedStudents from '../ApprovedStudents';
import ContactArea from '../contact-area';
import ApprovementArea from './approvement';
import UnitHeroArea from './hero-area';
import MapArea from './map-area';
import PictureArea from './pictures-area';

const UnitPage = ({ unit }) => {
  return (
    <>
      <UnitHeroArea unit={unit} />
      <ApprovementArea unit={unit} />
      <PictureArea unit={unit} />
      <MapArea unit={unit} />
      <ApprovedStudents />
      <ContactArea />
    </>
  );
};

UnitPage.propTypes = {
  unit: PropTypes.oneOf(['portao', 'bacacheri']).isRequired,
};

export default UnitPage;
