import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container, Row, Col } from '../../../components/ui/Wrapper';
import Heading from '../../../components/ui/Heading';
import Maps from '../../../components/maps';
import { SectionWrap } from './style';

const MapArea = ({ unit }) => {
  const heroData = useStaticQuery(graphql`
    query MapQuery {
      allUnitJson(filter: { id: { regex: "/map-content/" } }) {
        edges {
          node {
            unit
            address
            center {
              lat
              lng
            }
          }
        }
      }
    }
  `);

  const {
    node: { address, center },
  } = heroData.allUnitJson.edges.find(({ node }) => node.unit === unit);

  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <Heading as="h3" mb="40px" textalign="center">
              {address}
            </Heading>
            <Maps center={center} />
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default MapArea;
