import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Image from '../../../components/Image';
import SectionTitle from '../../../components/ui/section-title';
import { Col, Container, Row } from '../../../components/ui/Wrapper';
import { Cat, Figure, Info, Description, Title, SectionWrap } from './approvement-area.style';

const ApprovementArea = props => {
  const approvementData = useStaticQuery(graphql`
    query ApprovementDataQuery {
      allUnitJson(filter: { id: { regex: "/approvement-content/" } }) {
        edges {
          node {
            unit
            title
            subtitle
            description
            image {
              childImageSharp {
                fluid(maxWidth: 330, maxHeight: 330, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  `);

  const { sectionTitleStyle, unit } = props;
  const {
    node: { title, subtitle, description, image },
  } = approvementData.allUnitJson.edges.find(({ node }) => node.unit === unit);

  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle {...sectionTitleStyle} title={title} />
          </Col>
        </Row>

        <div className="item">
          <Row>
            <Col lg={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
              <Info>
                <Title>{subtitle}</Title>
                <Description dangerouslySetInnerHTML={{ __html: description }} />
              </Info>
            </Col>
            <Col lg={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
              <Figure>
                <Image fluid={image.childImageSharp.fluid} alt={title} title={title} />
              </Figure>
            </Col>
          </Row>
        </div>
      </Container>
    </SectionWrap>
  );
};

ApprovementArea.propTypes = {
  unit: PropTypes.oneOf(['portao', 'bacacheri']).isRequired,
  sectionTitleStyle: PropTypes.object,
};

ApprovementArea.defaultProps = {
  sectionTitleStyle: {
    mb: '60px',
    responsive: {
      small: {
        mb: '47px',
      },
    },
  },
};

export default ApprovementArea;
