import styled from 'styled-components';
import { device } from '../../../theme';

export const SectionWrap = styled.section`
  padding-top: 100px;
  margin-bottom: 20px;
  @media ${device.medium} {
    padding-top: 80px;
    margin-bottom: 60px;
  }
  @media ${device.small} {
    padding-top: 60px;
    margin-bottom: 60px;
  }
`;

export const Info = styled.div`
  margin-top: 30px;
  @media ${device.medium} {
    margin-top: 0;
  }
`;

export const Title = styled.h4`
  margin-bottom: 10px;
  font-weight: 500;
`;

export const Cat = styled.h5`
  color: ${props => props.theme.colors.primaryLight};
  margin-bottom: 15px;
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 1.67;
`;

export const Figure = styled.figure``;
